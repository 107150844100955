import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Layout from '../layout';

import TemplateList from '../components/TemplateList/TemplateList';
import SEO from '../components/SEO/SEO';
import Jumbotron from '../components/Jumbotron/Jumbotron';
import LoginForm from '../components/LoginForm/LoginForm';
import DynamicTabContent from '../components/DynamicTabContent/DynamicTabContent';
import LogosReferences from '../components/References/LogosReferences';
import StartNowButton from '../components/Button/StartNowButton';

import config from '../../data/SiteConfig';
import styles from './home.module.scss';
import colors from '../scss/_colors.scss';

class Index extends React.Component {
  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges;
    return (
      <Layout>
        <div className="index-container">
          <Helmet title={config.siteTitle} />
          <SEO />

          <Jumbotron
            headline1="Occupez-vous de vos affaires"
            headline2="avec Spreadsheep"
            subtitle="Importez, collectez vos données et collaborez efficacement avec la puissance d'une base de
                données. Comme beaucoup de petites équipes, automatisez votre activité avec simplicité en quelques
                clics."
          />

          <LoginForm cta="Commencer maintenant" />
          <div className="separator">
            <h1>Une application métier personnalisée construite par vous et pour vous</h1>
          </div>

          <div className={styles.argumentGrid}>
            <div>
              <FontAwesomeIcon size="2x" icon="file-import" color={colors.green} />
              <h2>Importez et collectez vos données</h2>
              <p>
                Transformez vos fichiers Excel en application en quelques minutes. Automatisez l&apos;import de
                données (xls, csv) et collectez des données à partir de formulaires simples et ergonomiques.
              </p>
            </div>
            <div>
              <FontAwesomeIcon size="2x" icon="users" color={colors.blue} />
              <h2>Collaborez avec vos équipes</h2>
              <p>
                Travaillez à plusieurs en temps réel. Que vous soyez au bureau ou sur le terrain (grâce à
                l&apos;application mobile), vous partagez le même niveau d&apos;information.
              </p>
            </div>
            <div>
              <FontAwesomeIcon size="2x" icon={['far', 'file-pdf']} color={colors.red} />
              <h2>Générez vos documents PDF</h2>
              <p>
                Générez automatiquement vos documents professionnels et administratifs à partir de vos données
                (factures, devis, attestations…)
              </p>
            </div>
          </div>

          <DynamicTabContent />

          <div className="separator-box">
            <h1>
              Spreadsheep réunit le meilleur des tableurs et des bases de données en une application unique
              s&apos;adressant à tout type d&apos;organisation.
            </h1>
          </div>

          <TemplateList postEdges={postEdges} />
          <div className="separator">
            <h1>Pensé pour les petites équipes agiles</h1>
          </div>

          <div className={styles.argumentGrid}>
            <div>
              <FontAwesomeIcon size="2x" icon="check-circle" color={colors.green} />
              <h2>Simple</h2>
              <p>Un seul outil vous permet d’associer la gestion de projet à tous les aspects de votre activité.</p>
            </div>
            <div>
              <FontAwesomeIcon size="2x" icon="pencil-alt" color={colors.blue} />
              <h2>Personnalisable</h2>
              <p>
                Affichez uniquement l&apos;information utile : Exploitez et visualisez les d&apos;une façon inédite.
              </p>
            </div>
            <div>
              <FontAwesomeIcon size="2x" icon={['far', 'file-pdf']} color={colors.red} />
              <h2>Professionel</h2>
              <p>
                Automatisez la production de vos documents professionnels : rapports, factures, fiches
                d&apos;intervention, etc.
              </p>
            </div>
            <div>
              <FontAwesomeIcon size="2x" icon="clock" color={colors.purple} />
              <h2>Rapide</h2>
              <p>Retrouvez l&apos;information que vous cherchez grâce aux tableaux de bords personnalisés.</p>
            </div>
            <div>
              <FontAwesomeIcon size="2x" icon="lock" color={colors.orange} />
              <h2>Sécurisé</h2>
              <p>
                Décidez qui peut accéder à quelle information et évitez les erreurs de saisie et de mise à jour des
                informations.
              </p>
            </div>
            <div>
              <FontAwesomeIcon size="2x" icon="user-plus" color={colors.cyan} />
              <h2>Collaboratif</h2>
              <p>
                Coopérez sans limite avec toutes les personnes pertinentes pour votre mission : collaborateurs,
                clients, partenaires…
              </p>
            </div>
          </div>

          <div className="separator">
            <h1>Ils nous font confiance :</h1>
          </div>
          <LogosReferences />
          <div className={styles.secondCta}>
            <StartNowButton cta="Commencez maintenant" url="https://beta.spreadsheep.io" />
          </div>
        </div>
      </Layout>
    );
  }
}

export default Index;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [fields___date], order: DESC }
      filter: {frontmatter:{pagetype:{eq: "template"}} }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            category
            cover
            color
            date
          }
        }
      }
    }
  }
`;
