import React, { useState } from 'react';

import styles from './DynamicTabContent.module.scss';

import captureWidgets from '../../../content/img/capture_widgets.png';
import reportBuilderSpreadsheep from '../../../content/img/report_builder_spreadsheep.gif';
import mapSpreadsheep from '../../../content/img/map_spreadsheep.gif';

const DynamicTabContent = () => {
  const [activeTab, setActiveTab] = useState(2);
  const handleClick = (tab) => {
    return () => {
      return setActiveTab(tab);
    };
  };

  return (
    <div className={styles.bhoechieTabContainer}>
      <div className={styles.bhoechieTabMenu}>
        <div href="#" className={`${styles.tab} ${styles.tab1} ${activeTab === 1 ? styles.active : ''}`} onClick={handleClick(1)}>
          <h3>Listes déroulantes, dates, cases à cocher</h3>
          <p>
            Simplifiez-vous la saisie et la collecte de données. Evitez les erreurs et les doubles saisies. Soyez
            efficace !
          </p>
        </div>
        <div href="#" className={`${styles.tab} ${styles.tab2} ${activeTab === 2 ? styles.active : ''}`} onClick={handleClick(2)}>
          <h3>Documents &amp; Rapports</h3>
          <p>
            Créez vos documents et rapports grâce au drag&amp;drop et remplissez les automatiquement à partir de vos
            données (factures, devis…)
          </p>
        </div>
        <div href="#" className={`${styles.tab} ${styles.tab3} ${activeTab === 3 ? styles.active : ''}`} onClick={handleClick(3)}>
          <h3>Cartographie</h3>
          <p>
            Ajoutez une dimension spatiale inédite à vos données et exploitez-les sur une carte OpenStreetMap,
            cadastre ou photo aérienne
          </p>
        </div>

        <div className={`${styles.bhoechieTabContent} ${styles.img1} ${activeTab === 1 ? styles.active : ''}`}>
          <img className="img-responsive" src={captureWidgets} alt="widget dropdown" />
        </div>
        <div className={`${styles.bhoechieTabContent} ${styles.img2} ${activeTab === 2 ? styles.active : ''}`}>
          <img className="img-responsive" src={reportBuilderSpreadsheep} alt="report builder" />
        </div>
        <div className={`${styles.bhoechieTabContent} ${styles.img3} ${activeTab === 3 ? styles.active : ''}`}>
          <img className="img-responsive" src={mapSpreadsheep} alt="map widget" />
        </div>
      </div>
    </div>
  );
};

export default DynamicTabContent;
