import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styles from './logosReferences.module.scss';

const LogosReferences = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "img/references" } }) {
        edges {
          node {
            childImageSharp {
              fixed(height: 50, duotone: {highlight: "#fffdfa", shadow: "#00112b"}) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);

  const d = data && data.allFile && data.allFile.edges && data.allFile.edges.map((n) => {
    return (
      <Img
        fixed={n.node.childImageSharp.fixed}
        key={n.node.childImageSharp.fixed.src}
      />
    );
  });
  return <div className={styles.logosReferences}>{d}</div>;
  // return <Img fixed={data.placeholderImage.childImageSharp.fixed} />
};

export default LogosReferences;
