import React from 'react';

import TemplateCard from './TemplateCard';
import styles from './Template.module.scss';

class TemplateList extends React.Component {
  getPostList() {
    const postList = [];
    const { postEdges } = this.props;
    postEdges.forEach((postEdge) => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        category: postEdge.node.frontmatter.category,
        cover: postEdge.node.frontmatter.cover,
        color: postEdge.node.frontmatter.color,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead,
      });
    });
    return postList;
  }

  render() {
    const postList = this.getPostList();
    return (
      <div className={styles.list}>
        {/* Your post list here. */
        postList.map((post) => {
          return (
            <TemplateCard
              key={post.title}
              url={post.path}
              title={post.title}
              tags={post.tags}
              category={post.category}
              cover={post.cover}
              color={post.color}
            />
          );
        })}
      </div>
    );
  }
}

export default TemplateList;
