import React, { useState } from 'react';
import Button from '../Button/Button';

import styles from './LoginForm.module.scss';

const LoginForm = ({ cta }) => {
  const [email, setEmail] = useState('');

  const handleOnSubmit = (e) => {
    e.preventDefault();
    window.location = `https://beta.spreadsheep.io/#/sign-up?email=${email}`;
  };
  return (
    <form className={styles.form} onSubmit={handleOnSubmit}>

      <input
        type="email"
        className="form-control"
        autoComplete="email"
        id="InputEmail"
        name="email"
        onChange={(e) => { setEmail(e.target.value); }}
        value={email}
        placeholder="Entrez votre adresse email"
      />

      <Button type="submit" bold>{cta}</Button>
    </form>
  );
};

export default LoginForm;
